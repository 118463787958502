<template>
    <section>
        <modal ref="modalCambioPrecio" titulo="Solicitar Cambio de Precio" tamano="modal-lg" no-aceptar adicional="Solicitar" @adicional="solicitar_cambio">
            <div class="row mx-5">
                <div class="col-3 px-0 text-center">
                    <img :src="producto.imagen" class="obj-cover br-12 border p-1" width="150" height="150" />
                </div>
                <div class="col-9 px-0">
                    <p class="text-general f-14 f-600">
                        {{ producto.nombre }}
                    </p>
                    <div>
                        <div class="row mx-0 my-2">
                            <p class="col-auto px-0 text-general f-13">
                                {{ promocion.activa ? promocion.valor : producto.precio_final }}
                            </p>
                            <p v-show="promocion.activa" class="col-auto text-gris descuento f-600 f-13">
                                {{ convertMoney(producto.precio, pedido.idm_moneda) }}
                            </p>
                        </div>
                        <div v-show="promocion.activa" class="row mx-0 align-items-center">
                            <div class="bg-general text-white px-3 br-12 f-11">
                                {{ promocion.texto }}
                            </div>
                        </div>
                    </div>
                    <p class="text-general my-2 f-14">
                        <b class="f-500">Barcode: </b> {{ producto.barcode != null ? producto.barcode : 'No registra' }}
                        <b class="f-500 ml-3">SKU: </b> {{ producto.sku != null ? producto.sku : 'No registra' }}
                    </p>
                    <p class="text-general my-2 f-14">
                        <b class="f-500">Unidades disponibles: </b> {{ agregarSeparadoresNumero(producto.unidades_disponibles) }}
                    </p>
                    <p class="text-general my-2 f-14">
                        <b class="f-500">Venta sin stock: </b> {{ producto.venta_sin_stock == 1 ? 'Si' : 'No' }}
                    </p>
                    <p class="text-general my-2 f-13">
                        <b class="f-500">Presentacion: </b> {{ producto.presentacion }}
                    </p>
                    <p class="text-general my-2 f-13">
                        <b class="f-500"> Unidad de Medida: </b> {{ producto.unidad }}
                    </p>
                    <p class="text-general my-2 f-13">
                        <b class="f-500">Venta mínima:</b> {{ producto.cantidad_minima }} {{ producto.unidad }}
                    </p>
                    <div class="row mx-0 justify-content-between">
                        <p class="text-general col-auto px-0 f-13">
                            <b> Cantidad Pedida:</b> {{ producto.cantidad_pedida }}
                        </p>
                        <p class="text-general col-auto f-13">
                            <b> Valor Total:</b> {{ producto.total_pedida }}
                        </p>
                    </div>
                    <div class="row mx-0 text-general my-4">
                        <div class="col-4 px-0">
                            <p class="ml-2 f-13 f-500">Nuevo Precio</p>
                            <div class="d-middle">
                                <money v-model="sugerencia.unidad_sugerida" class="input-money" v-bind="money" />
                            </div>
                        </div>
                        <div class="col">
                            <p class="ml-2 f-13 f-500">Motivo del cambio de Precio</p>
                            <el-select v-model="sugerencia.id_motivo_solicitud" class="w-100" placeholder="Seleccionar motivo" size="small">
                                <el-option
                                v-for="item in motivos"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import {Money} from 'v-money'
import {mapGetters} from 'vuex'
import PedidosAdmin from '~/services/pedidos/pedidos-admin'
export default {
    components: {
        Money,
    },
    data(){
        return {
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            motivos:[],
            open_sugerencia:false,

            producto:{
                id:0,
                nombre:'',
                descripcion:'',
                comentario:'',
                imagen:'',
                precio: 0,
                precio_final: 0,
                presentacion:'',
                unidad:'',
                cantidad_minima:0,
                cantidad_pedida: 0,
                total_pedida: 0
            },
            sugerencia:{
                activa:false,
                estado:0,
                id_motivo_solicitud:null,
                unidad_sugerida:0,
                porcentaje:''
            },
            promocion:{
                activa:false,
                texto:'',
                valor:0
            },
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
        })
    },
    methods: {
        toggle(prod){
            //return
            this.limpiar()
            if(this.motivos.length === 0){
                this.selectMotivos()
            }

            let decimalesFrancion = 0
            if(prod.producto.cantidad_tipo === 1){
                decimalesFrancion = 0
            } else{
                decimalesFrancion = 2
            }

            this.money.precision = this.pedido.decimales_moneda
            this.producto = {
                id:prod.producto.id,
                id_pedido_producto: prod.id,
                nombre:prod.producto.nombre,
                imagen:prod.producto.imagen,
                precio: prod.unidad_teorica,
                precio_final: this.convertMoney(prod.unidad_final,this.pedido.idm_moneda),
                presentacion:prod.producto.presentacion,
                unidad:prod.producto.unidad,
                cantidad_minima:this.agregarSeparadoresNumero(prod.producto.cantidad_minima,decimalesFrancion),
                cantidad_pedida: this.agregarSeparadoresNumero(prod.cantidad,decimalesFrancion) + prod.producto.unidad,
                total_pedida: this.convertMoney(prod.total_final,this.pedido.idm_moneda),
                barcode: prod.barcode,
                sku: prod.sku,
                unidades_disponibles: prod.unidades_disponibles,
                venta_sin_stock: prod.venta_sin_stock
            }
            if(prod.sugerido){
                this.sugerencia.id_motivo_solicitud = prod.sugerido.id_motivo_solicitud
                this.sugerencia.unidad_sugerida =  parseFloat(prod.sugerido.unidad_sugerida)
            }
            if(prod.promocion){
                this.promocion.activa = true
                this.promocion.texto = prod.data_promocion.texto
                this.promocion.valor =  this.convertMoney(prod.unidad_final,this.pedido.idm_moneda)
            }
            this.$refs.modalCambioPrecio.toggle();
        },
        async solicitar_cambio(){
            try {

                if(this.sugerencia.id_motivo_solicitud == null || this.sugerencia.unidad_sugerida == 0){

                    this.notificacion('Advertencia','Por favor digite un valor y seleccione un motivo para continuar','warning')
                    return
                }

                if(this.sugerencia.unidad_sugerida > parseFloat(this.producto.precio)){
                    this.notificacion('Advertencia',`No puede sugerir un precio mayor al valor actual. ${this.convertMoney(this.producto.precio,this.pedido.idm_moneda)}`,'warning')
                    return
                }

                const model = {
                    id_motivo_solicitud: this.sugerencia.id_motivo_solicitud,
                    unidad_sugerida: this.sugerencia.unidad_sugerida,
                    id_producto: this.producto.id,
                    id_admin_pedido: this.id_pedido
                }

                const {data} = await PedidosAdmin.solicitarCambioPrecio(model)

                this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.id_pedido))
                this.notificacion('Éxito','Se ha creado la solicitud de cambio de precio','success')

                this.$refs.modalCambioPrecio.toggle();


            } catch (e){
                this.error_catch(e)
            }
        },
        async selectMotivos(){
            try {
                let params = { tipo:21 }
                const {data} = await PedidosAdmin.select_motivos(params)
                this.motivos = data.motivos

            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.producto = {
                id:0,
                nombre:'',
                imagen:'',
                precio: 0,
                precio_final: 0,
                presentacion:'',
                unidad:'',
                cantidad_minima:0,
                cantidad_pedida: 0,
                total_pedida: 0
            }
            this.sugerencia={
                id_motivo_solicitud:null,
                unidad_sugerida:0,
            }
            this.promocion={
                activa:false,
                texto:'',
                valor:0
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
</style>
